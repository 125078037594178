<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" :is-event-create-active.sync="isEventCreateAction"  />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
              
              :slotLabelFormat="{ 'hour12': false, 'hour': '2-digit', 'minute': '2-digit'}"  
            >
             <template v-slot:eventContent='arg'>
                <el-popover
                placement="top"
                :title= arg.event.extendedProps.createdByUserFullName 
                width="100"
                :visible-arrow="true"
                container='body'
                trigger="hover">
                 
                <span style="width:100px" >{{ arg.timeText + " " + arg.event.title }}</span>
              
                <div v-if="arg.event.extendedProps.candidate" slot="reference"
                     class="popper-content">
                    <i>{{ "From: " +  arg.event.extendedProps.candidate.candidateFullName }}</i>
                </div>
            </el-popover>

        </template>
         </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        :isEventCreateAction="isEventCreateAction"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import calendarStoreModule from "./calendarStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import useCalendar from "./useCalendar";
import moment from 'moment';

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
 data() {
    return {
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        interactionPlugin,
        momentPlugin
      ],
      defaultView: "timeGridWeek",
      firstDay: 1,
      nowIndicator: true,
      allDayText: "Ganztägig",
      slotDuration: "00:15",
      slotLabelFormat: "HH:mm",
      slotLabelInterval: "00:30",
      locale: "de",
      height: "auto",
      editable: true,
      datesAboveResources: true,


    };
  },
   methods: {
         handleMore() {
          
         },
    },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions, 
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventCreateAction,
    } = useCalendar();

    fetchEvents();

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventCreateAction,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.fc-daygrid-event {
    white-space: pre-line;
}
</style>
