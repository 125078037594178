import axios from '@axios'

export default {
    namespaced: true,
    state: {
        calendarOptions: [

            {
                color: 'danger',
                label: 'Events',
            },
            {
                color: 'success',
                label: 'Todos',
            },

        ],
        selectedCalendars: ['Events', "Todos"],
        selectedUser:[]
    },
    getters: {},
    mutations: {
        SET_SELECTED_EVENTS(state, val) {
            state.selectedCalendars = val
        },
        SET_SELECTED_USER(state, val) {
            state.selectedUser = val
        },
    },
    actions: {
        fetchEvents(ctx, { calendars,users,info }) {


            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Calendar/event`, {

                        calendars: calendars,
                        users:users,
                        pageSize: 1000,
                        currentPage: 1,
                        sortExpression: null,
                        sortDirection: null,
                        includeDeletedRecords: false,
                        fromDateUtc: info.startStr,
                        toDateUtc: info.endStr

                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCandidate(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Candidate/simple-list`, {
                        pageSize: 20000,
                        currentPage: 1,
                        sortExpression: null,
                        sortDirection: "asc",
                        includeDeletedRecords: false,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addEvent(ctx, { event }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Calendar/event/create`, event)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateEvent(ctx, { event }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_BASE_URL}/api/Calendar/event`, event)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeEvent(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${process.env.VUE_APP_BASE_URL}/api/Calendar/event/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/User/simple-list`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}