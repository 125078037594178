import store from '@/store'
import useCalendar from "../useCalendar";
import { computed } from '@vue/composition-api'

export default function useCalendarSidebar() {
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)
  const { users } = useCalendar();
  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed({
    get: () => store.state.calendar.selectedCalendars,
    set: val => {
      store.commit('calendar/SET_SELECTED_EVENTS', val)
    },
  })

  const selecteduser = computed({
    get: () => store.state.calendar.selectedUser,
    set: val => {
      store.commit('calendar/SET_SELECTED_USER', val)
      
    },
  })


  // ------------------------------------------------
  // checkAll
  // ------------------------------------------------
  const checkAll = computed({
  
    get: () => selectedCalendars.value.length === calendarOptions.value.length,
    set: val => {
      if (val) {
        selectedCalendars.value = calendarOptions.value.map(i => i.label)
      } else if (selectedCalendars.value.length === calendarOptions.value.length) {
        selectedCalendars.value = []
      }
    },
  })

  const checkAllUser = computed({

    get: () => selecteduser.value.length === users.value.length,
    set: val => {
      if (val) {
        selecteduser.value = users.value.map(i => i.userId)
      } else if (selecteduser.value.length === users.value.length) {
        selecteduser.value = []
      }

    },
  })

  return {
    calendarOptions,
    selectedCalendars,
    checkAllUser,
    selecteduser,
    checkAll,
    users
  }
}
