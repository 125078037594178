// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment';
export default function userCalendar() {
    // Use toast
    const toast = useToast()
    // ------------------------------------------------
    // refCalendar
    // ------------------------------------------------
    const refCalendar = ref(null)
    const users = ref([{
        id: store.state.user.user.userId,
        fullName: store.state.user.user.fullName
    }])

    // ------------------------------------------------
    // calendarApi
    // ------------------------------------------------
    let calendarApi = null
    onMounted(() => {
        calendarApi = refCalendar.value.getApi()
    })

    // ------------------------------------------------
    // calendars
    // ------------------------------------------------
    const calendarsColor = {
        Business: 'primary',
        Todos: 'success',
        Events: 'danger',
        Family: 'warning',
        ETC: 'info',
    }

    // ------------------------------------------------
    // event
    // ------------------------------------------------
    const blankEvent = {
        title: '',
        start: '',
        end: '',
        allDay: false,
        url: '',
        extendedProps: { calendar: '', location: '', description: '', },

    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))

    const clearEventData = () => {
        event.value = JSON.parse(JSON.stringify(blankEvent))

    }




    // ------------------------------------------------
    // (UI) updateEventInCalendar
    // ------------------------------------------------
    const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
        toast({
            component: ToastificationContent,
            props: {
                title: 'Event Updated',
                icon: 'CheckIcon',
                variant: 'success',
            },
        })

        const existingEvent = calendarApi.getEventById(updatedEventData.id)

        for (let index = 0; index < propsToUpdate.length; index++) {
            const propName = propsToUpdate[index]
            existingEvent.setProp(propName, updatedEventData[propName])
        }


        existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })


        for (let index = 0; index < extendedPropsToUpdate.length; index++) {
            const propName = extendedPropsToUpdate[index]
            existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
        }
    }

    // ------------------------------------------------
    // (UI) removeEventInCalendar
    // ------------------------------------------------
    const removeEventInCalendar = eventId => {
        toast({
            component: ToastificationContent,
            props: {
                title: 'Event Removed',
                icon: 'TrashIcon',
                variant: 'danger',
            },
        })
        calendarApi.getEventById(eventId).remove()
    }


    // ------------------------------------------------
    const grabEventDataFromEventApi = eventApi => {
        const {
            id,
            title,
            start,
            end,
            extendedProps: { calendar, guests, location, description, timeLength, eventUrl, candidate },
            allDay,

        } = eventApi

        return {
            id,
            title,
            start,
            end,
            extendedProps: { calendar, guests, location, description, timeLength, eventUrl, candidate },
            allDay,

        }
    }

    // ------------------------------------------------
    // addEvent
    // ------------------------------------------------
    const addEvent = eventData => {

        const _eventData = {
            title: eventData.title,
            description: eventData.extendedProps.description,
            eventUrl: eventData.extendedProps.eventUrl,
            location: eventData.extendedProps.location,
            calendar: "Events",
            start: eventData.start,
            end: eventData.allDay ? null : endDateFix(eventData.start, eventData.extendedProps.timeLength, eventData.end),
            timeLength: eventData.extendedProps.timeLength,
            allDay: eventData.allDay,
            candidateId: eventData.extendedProps.candidate?.candidateId ? eventData.extendedProps.candidate.candidateId : null,
        }
        store.dispatch('calendar/addEvent', { event: _eventData }).then(() => {
            // eslint-disable-next-line no-use-before-define
            refetchEvents()
        }).catch((error) => {

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "error",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                        text: value,
                    },
                });
            }
        });
    }

    // ------------------------------------------------
    // updateEvent
    // ------------------------------------------------
    const updateEvent = eventData => {

        if (eventData.end == null)
            eventData.end = endDateFix(eventData.start, "1 h", null);

        const _eventData = {
            id: eventData.id,
            title: eventData.title,
            description: eventData.extendedProps.description,
            eventUrl: eventData.extendedProps.eventUrl,
            location: eventData.extendedProps.location,
            calendar: "Events",
            start: eventData.start,
            end: eventData.end ? eventData.end : endDateFix(eventData.start, eventData.extendedProps.timeLength, eventData.end),
            timeLength: eventData.extendedProps.timeLength,
            allDay: eventData.allDay,
            candidateId: eventData.extendedProps.candidate?.candidateId ? eventData.extendedProps.candidate.candidateId : null,
        }

        store.dispatch('calendar/updateEvent', { event: _eventData }).then(response => {
            refetchEvents()
            const updatedEvent = response.data.event

            const propsToUpdate = ['id', 'title', 'url']
            const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description', 'timeLength', 'eventUrl', 'candidate']

            updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)

        }).catch((error) => {

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "error",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                        text: value,
                    },
                });
            }
        });
    }

    const endDateFix = (date, time, end) => { //"15 m","30 m","45 m","1 h","2 h","3 h"

        switch (time) {
            case "15 m":
                return moment(date).add(15, 'm').toDate();
            case "30 m":
                return moment(date).add(30, 'm').toDate();
            case "45 m":
                return moment(date).add(45, 'm').toDate();
            case "1 h":
                return moment(date).add(1, 'h').toDate();
            case "2 h":
                return moment(date).add(2, 'h').toDate();
            case "3 h":
                return moment(date).add(3, 'h').toDate();
            default:
                return end
        }
    }

    // ------------------------------------------------
    // removeEvent
    // ------------------------------------------------
    const removeEvent = () => {
        const eventId = event.value.id
        store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
            removeEventInCalendar(eventId)
        })
    }

    // ------------------------------------------------
    // refetchEvents
    // ------------------------------------------------
    const refetchEvents = () => {
        calendarApi.refetchEvents()
    }

    // ------------------------------------------------
    // selectedCalendars
    // ------------------------------------------------
    const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

    watch(selectedCalendars, () => {
        refetchEvents()
    })

    const selecteduser = computed(() => store.state.calendar.selectedUser)

    watch(selecteduser, () => {
        refetchEvents()
    })

    // --------------------------------------------------------------------------------------------------
    // AXIOS: fetchEvents
    // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
    // --------------------------------------------------------------------------------------------------
    const fetchEvents = (info, successCallback) => {
        // If there's no info => Don't make useless API call

        if (!info) return

        // Fetch Events from API endpoint
        store
            .dispatch('calendar/fetchEvents', {
                calendars: selectedCalendars.value, users: selecteduser.value, info: info
            })
            .then(response => {


                successCallback(response.data.items)

            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching calendar events',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })





    }

    // ------------------------------------------------------------------------
    // calendarOptions
    // * This isn't considered in UI because this is the core of calendar app
    // ------------------------------------------------------------------------
    const calendarOptions = ref({
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
            start: 'sidebarToggle, prev,next, title',
            end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: true,
        meridiem: 'narrow',
        locale: "tr",
        events: fetchEvents,

        editable: true,
        eventResizableFromStart: true,
        dragScroll: true,
        dayMaxEvents: 2,
        navLinks: true,

        eventClassNames({ event: calendarEvent }) {
            // eslint-disable-next-line no-underscore-dangle
            const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

            return [
                // Background Color
                `bg-light-${colorName}`,
            ]
        },
        eventClick({ event: clickedEvent }) {
            isEventCreateAction.value = false;
            event.value = grabEventDataFromEventApi(clickedEvent)

            // eslint-disable-next-line no-use-before-define
            isEventHandlerSidebarActive.value = true
        },

        customButtons: {
            sidebarToggle: {
                // --- This dummy text actual icon rendering is handled using SCSS ----- //
                text: 'sidebar',
                click() {
                    // eslint-disable-next-line no-use-before-define
                    isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
                },
            },
        },

        dateClick(info) {
            console.log(info);
            isEventCreateAction.value = true;
            event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
            // eslint-disable-next-line no-use-before-define
            isEventHandlerSidebarActive.value = true
        },


        eventDrop({ event: droppedEvent }) {
            console.log(droppedEvent);
            updateEvent(grabEventDataFromEventApi(droppedEvent))
        },


        eventResize({ event: resizedEvent }) {
            updateEvent(grabEventDataFromEventApi(resizedEvent))
        },

        // Get direction from app state (store)
        direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
        rerenderDelay: 350,
    })


    // if (store.state.user.user.role === "Administrator") {
        store
            .dispatch('calendar/fetchUsers', {
                pageSize: 100,
                currentPage: 1,
                sortExpression: null,
                sortDirection: "asc",
                //searchEmail:searchQuery.value,
                searchNamePart: null,
            })
            .then(response => {
                const { data } = response.data;
                users.value = (data)
                let list = []
                data.map(x => list.push(x.userId))
                store.commit('calendar/SET_SELECTED_USER', list)

            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching users list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    // }
    // else {
    //     let list = []
    //     users.value.map(x => list.push(x.id))
    //     store.commit('calendar/SET_SELECTED_USER', list)
    // }
    // ------------------------------------------------------------------------

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const isEventHandlerSidebarActive = ref(false)

    const isCalendarOverlaySidebarActive = ref(false)

    const isEventCreateAction = ref(true)

    return {
        refCalendar,
        isCalendarOverlaySidebarActive,
        isEventCreateAction,
        calendarOptions,
        event,
        clearEventData,
        addEvent,
        updateEvent,
        removeEvent,
        refetchEvents,
        fetchEvents,
        users,
        // ----- UI ----- //
        isEventHandlerSidebarActive,
    }
}