<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">{{ eventLocal.id ? "Update" : "Add" }} Event</h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-event');
                hide();
              "
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group label="Title" label-for="event-title">
                <b-form-input
                  id="event-title"
                  v-model="eventLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Event Title"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              rules="required"
            >
              <b-form-group
                label="Start Date"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Z',
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    time_24hr: true,
                  }"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <validation-provider
              v-if="!isEventCreateAction && eventLocal.extendedProps.calendar !== 'Todos' && !eventLocal.allDay"
              #default="validationContext"
              name="End Date"
              rules="required"
            >
              <b-form-group
                label="End Date"
                label-for="end-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Z',
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    time_24hr: true,
                  }"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              #default="validationContext"
              name="End Date"
              
            >
              <b-form-group
                label="Time Length"
                label-for="end-date"
                :state="getValidationState(validationContext)"
                v-if="isEventCreateAction"
                
              >
                <!--  <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true,}"
                /> timeLength -->
                <v-select
                  v-model="eventLocal.extendedProps.timeLength"
                  :options="time"
                  label="label"
                  input-id="end-date"
                >
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- All Day -->
            <b-form-group>
              <b-form-checkbox
                v-model="eventLocal.allDay"
                name="check-button"
                switch
                inline
              >
                All Day
              </b-form-checkbox>
            </b-form-group>

            <validation-provider #default="validationContext" name="End Date">
              <b-form-group
                label="Candidate"
                label-for="Candi-date"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.candidate"
                  :options="candidateList"
                  label="candidateFullName"
                  input-id="Candi-date"
                >
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Event URL -->
            <validation-provider
              #default="validationContext"
              name="Event URL"
              rules="url"
            >
              <b-form-group label="Event URL" label-for="event-url">
                <b-form-input
                  id="event-url"
                  v-model="eventLocal.extendedProps.eventUrl"
                  type="url"
                  :state="getValidationState(validationContext)"
                  placeholder="htttps://www.google.com"
                  trim
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Location -->
            <b-form-group label="Location" label-for="event-location">
              <b-form-input
                id="event-location"
                v-model="eventLocal.extendedProps.location"
                trim
                placeholder="Event Location"
              />
            </b-form-group>

            <!-- Textarea -->
            <b-form-group label="Description" label-for="event-description">
              <b-form-textarea
                id="event-description"
                v-model="eventLocal.extendedProps.description"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div
              class="d-flex mt-2"
              v-if="eventLocal.extendedProps.calendar !== 'Todos'"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventLocal.id ? "Update" : "Add " }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </validation-observer>

        <b-row class="mt-5">
          <b-col cols="12" md="12"> 
           <b-button  v-if="eventLocal.extendedProps.candidate? (eventLocal.extendedProps.candidate.candidateId? true:false):false"
             variant="info"
             class="m-2"
              :to="{ name: 'apps-view', params: { id: eventLocal.extendedProps.candidate.candidateId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-3">View Resume</span>
            </b-button>
          
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="12" md="12">
            <a
              class="m-2"
              :href="eventLocal.extendedProps.eventUrl"
              target="_blank"
              >{{ eventLocal.extendedProps.eventUrl }}</a
            >
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, ///apps/view/13
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BRow,
  BCol,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs } from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";
import store from "@/store";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEventHandlerSidebarActive",
    event: "update:is-event-handler-sidebar-active",
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEventCreateAction: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      time: ["15 m", "30 m", "45 m", "1 h", "2 h", "3 h"],
      required,
      email,
      url,
    };
  },
  created: function () {
    console.log("candidateList", this.candidateList);
  },

  setup(props, { emit }) {
    const clearFormData = ref(null);
    const candidateList = ref([]);

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetEventLocal, props.clearEventData);

    clearFormData.value = clearForm;

    store
      .dispatch("calendar/fetchCandidate")
      .then((response) => {
        candidateList.value = response.data.data;
      })
      .catch(() => {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: value,
          },
        });
        }
      });

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,
      candidateList,
      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
